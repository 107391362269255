
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      type: "",
      groupname: "",
      file: "",
      group_id: "",
    });
    const groupList = ref<any>("");
    const optionValue = ref<any>("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const fileData = ref<any>("");
    const page = ref<string>("");

    // const value = ref('');
    const registration = Yup.object().shape({
      type: Yup.string().required().label("Select Option"),
    });

    onMounted(() => {
      const data = route.params.id;
      if (data) {
        // getUser(id);
        // page.value = "Edit";
        setCurrentPageBreadcrumbs("Edit Plan", ["Plan"]);
      } else {
        // page.value = "Add";
        getGrouplist();
        setCurrentPageBreadcrumbs("Import Contact", []);
      }

      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        // getUser(id);
        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getGrouplist = () => {
      var request = {
        url: `group/all`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;
          groupList.value = data.data;
          // console.log(data)
        }
      });
    };

    const changefile = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target) {
          const text = e.target.result;
          const data = csvToArray(text);
          fileData.value = data;
          console.log("text", JSON.stringify(data));
        }
      };
      reader.readAsText(file);
    };
    const csvToArray = (str, delimiter = ",") => {
      var headers = str.slice(0, str.indexOf("\n")).split(delimiter);
      headers = headers.map(function (el) {
        return el.trim();
      });
      var rows = str.slice(str.indexOf("\n") + 1).split("\n");
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
          if (values[index] != undefined) {
            // console.log('sdfsd',object[header] == 'first_name' )
            if (values[index] == "") {
              return object;
            } else {
              object[header] = values[index].trim();
            }
          } else {
            return object;
          }

          return object;
        }, {});

        return el;
      });
      // return the array
      // console.log('sdsd',arr);
      return arr;
    };

    const selectOption = (e) => {
      var data = e.target.value;
      optionValue.value = data;
    };

    const onSubmitRegister = (values, { resetForm }) => {
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      console.log("values", values);
      if (values.type == "new_group") {
        if (values.groupname == undefined) {
          Swal.fire({
            text: "Group Name is required!",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
            submitButton.value!.disabled = false;
        } else if (values.file == undefined) {
          Swal.fire({
            text: "Please upload csv file!",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
            submitButton.value!.disabled = false;
        } else {
          loading.value = true;
          var newGroup = {
            url: "contact-import",
            data: {
              type: values.type,
              groupname: values.groupname,
              contact: fileData.value,
              group_id: values.group_id,
            },
          };
          store
            .dispatch(Actions.POST, newGroup)
            .then((data) => {
              if (data) {
                Swal.fire("Success", "Contact import successfully!", "success");
                router.push({ name: "dashboard" });
              }
              loading.value = false;
            })
            .catch(() => {
              const [error] = Object.keys(store.getters.getErrors);
              Swal.fire({
                text: store.getters.getErrors[error],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      } else if (values.type == "exsitng_group") {
        if (values.group_id == undefined) {
          Swal.fire({
            text: "Select existing group is required!",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
            submitButton.value!.disabled = false;
        } else if (values.file == undefined) {
          Swal.fire({
            text: "Please upload csv file!",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
            submitButton.value!.disabled = false;
        } else {
          loading.value = true;
          var existingGroup = {
            url: "contact-import",
            data: {
              type: values.type,
              groupname: values.groupname,
              contact: fileData.value,
              group_id: values.group_id,
            },
          };
          store
            .dispatch(Actions.POST, existingGroup)
            .then((data) => {
              if (data) {
                Swal.fire("Success", "Contact import successfully!", "success");
                router.push({ name: "dashboard" });
              }
              loading.value = false;
            })
            .catch(() => {
              const [error] = Object.keys(store.getters.getErrors);
              Swal.fire({
                text: store.getters.getErrors[error],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      }

      // var type = '';
      // var groupname = '';
      // var file = '';
      // if(values.type != undefined){
      //      type = values.type;
      // }
      // if(values.groupname != undefined){
      //      groupname = values.groupname;
      // }
      // if(values.file != undefined){
      //     file = fileData;
      // }
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      page,
      formData,
      changefile,
      fileData,
      csvToArray,
      selectOption,
      optionValue,
      getGrouplist,
      groupList,
    };
  },
});
